<template>
  <div class="show-img-wrapper">
    <div v-if="imgSrc" class="_pic">
      <img :src="imgSrc" alt="">
<!--      <span v-if="id==='4634befe-d942-45b5-8bb1-7bc58bc51b16'" class="jump_btn_jsz_bxga" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/page/index.html#page/builderEquity/index.html?appid=scban')"></span>-->
      <span v-if="id==='56549731-afc9-4bf7-8371-3dc9bace11d2'" class="jump_btn_jsz_in99_a" @click="onClickPageLink('/shop_list?id=3e26d487-7d00-4f5b-a6af-14170e32bc7d')"></span>
      <span v-if="id==='56549731-afc9-4bf7-8371-3dc9bace11d2'" class="jump_btn_jsz_in99_b" @click="onClickPageLink('/shop_list?id=4238df75-c744-4254-af80-2e923a2b5331')"></span>
      <span v-if="id==='2af4b1f7-81ae-486d-a803-e777c779f5c5'" class="jump_btn_jsz_wfj" @click="onClickPageLink('/shop_list?id=47cb6616-0ed9-4f15-8e34-bce97771ae43')"></span>
      <span v-if="id==='3c2ca7c4-f11b-43df-921c-52bbc32748e8'" class="jump_btn_jsz_in99_1210_a" @click="onClickPageLink('/shop_list?id=3e26d487-7d00-4f5b-a6af-14170e32bc7d')"></span>
      <span v-if="id==='3c2ca7c4-f11b-43df-921c-52bbc32748e8'" class="jump_btn_jsz_in99_1210_b" @click="onClickPageLink('/shop_list?id=4238df75-c744-4254-af80-2e923a2b5331')"></span>
      <span v-if="id==='d06b0141-0ec4-4fce-b54b-56f3aad56918'" class="jump_btn_jdk_bx" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/page/index.html#page/72/P7200.html')"></span>
<!--      <span v-if="id==='4634befe-d942-45b5-8bb1-7bc58bc51b16'" class="jump_btn_jsz_bx" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/page/index.html#page/builderEquity/index.html')"></span>-->
      <span v-if="id==='c79efb1d-a1ec-4bd9-9f39-8388b887b3cc'" class="jump_btn_jsz_in99_1210_a" @click="onClickPageLink('/shop_list?id=3e26d487-7d00-4f5b-a6af-14170e32bc7d')"></span>
      <span v-if="id==='c79efb1d-a1ec-4bd9-9f39-8388b887b3cc'" class="jump_btn_jsz_in99_1210_b" @click="onClickPageLink('/shop_list?id=4238df75-c744-4254-af80-2e923a2b5331')"></span>
      <!-- 尊师卡 绿色理赔-->
      <span v-if="id==='a83b57a8-1ab8-4b27-a4d8-7eb0cd20d542'" class="jump_btn_zsk_lp_a" @click="onClickPageLink('/show_img?id=f7fb243c-91ca-47a9-8bcf-e85a7724533a')"></span>
      <span v-if="id==='a83b57a8-1ab8-4b27-a4d8-7eb0cd20d542'" class="jump_btn_zsk_lp_b" @click="onClickPageLink('/show_img?id=63652647-1e69-44a8-bfe1-3cc0aa123185')"></span>


    </div>

    <div class="_bottom_fixed" v-if="imgSrc">
      <div class="_height"></div>
      <div class="_bottom_back">
        <span class="_back_home" @click="$router.push('/home')"></span>
        <span class="_back_prev" @click="jumpGoBack()"></span>
      </div>
    </div>
    <div class="_right_bar_new_card" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')"  >
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png" >
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import page_links from "../mixin/page_links";
export default {
  components: {},
  mixins: [ page_links ],
  data() {
    return {
      imgSrc: '',
      id:""
    };
  },

  created() {
    console.log("-> [index.vue] created");
  },

  mounted() {
    console.info(!this.$route.query.callbackUrl);
    console.log("-> [index.vue] mounted");
    this.getImageShowData();
    this.getJsInfo();
  },

  methods: {
    jumpGoBack(){
      if(this.$route.query.callbackUrl){
        console.info(this.$route.query.callbackUrl);
        window.location.href=this.$route.query.callbackUrl;
      }else{
        this.$router.go(-1);
      }
    },
    async getJsInfo(){
      // 生成网页js签名
      let {data,code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if(code===200){
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      }
      wx.ready(function( ){
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function(res){
        console.info("wx error",res);
      });
    },
    async getImageShowData() {
      this.id = this.$route.query.id;
      if( this.id){
        let {data,code} = await this.$ajax.gateway("/api/api_get_resource_by_id", {
          "METHOD": "get",
          "id":  this.id
        });
        if(code==200){
          this.imgSrc =data.resources_img;
        }
      }
      if(this.$route.query.img){
        this.imgSrc =this.$route.query.img;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.show-img-wrapper {
  min-height: 20rem;
  background: #f6f6f6;
  ._pic {
    img {
      display: block;
      width: 100%;
    }
  }
  .jump_btn_zsk_lp_a{
    position: absolute;
    width: 8rem;
    left: 1rem;
    height: 1.8rem;
    top:15rem;
  }
  .jump_btn_zsk_lp_b{
    position: absolute;
    width: 8rem;
    left: 1rem;
    height: 1.8rem;
    top:19rem;
  }
  .jump_btn_jsz_bxga{
    position: absolute;
    width: 6rem;
    left: 2rem;
    height: 1.5rem;
    top:24rem;
    z-index: 999;
  }
  .jump_btn_jsz_in99_a{
    position: absolute;
    width: 6rem;
    left: 2rem;
    height: 1.3rem;
    top:77rem;
    z-index: 999;
  }
  .jump_btn_jsz_in99_b{
    position: absolute;
    width: 6rem;
    left: 2rem;
    height: 1.3rem;
    top:80rem;
    z-index: 999;
  }
  .jump_btn_jsz_wfj{
    position: absolute;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:60.5rem;
    z-index: 999;
  }
  .jump_btn_jsz_in99_1210_a{
    position: absolute;
    //background-color: green;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:44.5rem;
    z-index: 999;
  }
  .jump_btn_jsz_in99_1210_b{
    position: absolute;
    //background-color: green;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:47.5rem;
    z-index: 999;
  }
  .jump_btn_jdk_bx{
    position: absolute;
    //background-color: green;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:23.5rem;
    z-index: 999;
  }
  .jump_btn_jsz_bx{
    position: absolute;
    //background-color: green;
    width: 8rem;
    left: 1rem;
    height: 1.5rem;
    top:24rem;
    z-index: 999;
  }
  ._right_bar_new_card{
    position: fixed;
    top: 0.3rem;
    right: 0rem;
    img {
      height: 2.7rem;
    }
  }
}
</style>
